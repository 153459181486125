@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.flowby-spinner {
	animation: spin 0.55s linear infinite;
}
