body {
	padding: env(safe-area-inset-top) env(safe-area-inset-right)
		env(safe-area-inset-bottom) env(safe-area-inset-left);
}

#statusbar-fix {
	z-index: 9999;
	background: rgba(255, 255, 255, 1);
	overflow: hidden;
	position: fixed;
	top: 0px;
	display: block;
	width: 100%;
	height: env(safe-area-inset-top);
}

.chakra-modal__content-container {
	padding: env(safe-area-inset-top) env(safe-area-inset-right)
		env(safe-area-inset-bottom) env(safe-area-inset-left);
}
